/******************************************
 * DROPDOWN COMPONENT (TREE)
 * USED IN: Reporting (pages/reporting.js)
 *****************************************/

import React, { useState, useEffect, useRef } from "react"
// import "antd/dist/antd.min.css"
import { TreeSelect, ConfigProvider } from "antd"
import PropTypes from "prop-types"
import { getClientList } from "../../helpers/backend_helper"

// Locale
import { withTranslation } from "react-i18next"

const { SHOW_PARENT } = TreeSelect

const SelectDropdownTree = props => {
  const [loading, setLoading] = useState(false)
  const [customize] = useState(true)
  let defaultTreeOptions = {
    treeData: [],
    value: [],
    onChange: null,
    onTreeExpand: null,
    loadData: null,
    treeCheckable: true,
    allowClear: true,
    multiple: true,
    maxTagCount: 10,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: props.t("Choose group/customer"),
    style: { width: "100%" },
    treeDefaultExpandedKeys: ["all"],
    listHeight: 520,
    dropdownStyle: {
      maxHeight: "55vh",
      overflow: "visible",
    },
    virtual: false,
  }

  // const [options, setOptions] = useState([])
  const [treeOptions, setTreeOptions] = useState(defaultTreeOptions)
  const [allGroups, setAllGroups] = useState([])
  const [selectAll, setSelectAll] = useState(false)

  const controller = new AbortController()

  const [isShiftPressed, setIsShiftPressed] = useState(false)
  const isShiftPressedRef = useRef(isShiftPressed)
  const lastSelectedCustomerRef = useRef(null)
  const lastSelectedGroupRef = useRef(null)
  const customerFlatListRef = useRef([])
  const groupFlatListRef = useRef([])
  const clientTreeDataRef = useRef([])
  const fullListRef = useRef([])

  // Handle new data when either data is fetched or tag is used to filter
  useEffect(() => {
    fullListRef.current = clientTreeDataRef.current
    // Filter options by tag
    if (Array.isArray(props.selectedTags) && props.selectedTags.length > 0) {
      const filteredList = fullListRef.current
        .map(group => {
          // Get the tags from group
          const groupTagKeys = group.tags?.map(tag => tag.key) || []

          // Check if any of the group tags match the selected tags
          if (groupTagKeys.some(key => props.selectedTags.includes(key))) {
            return group // Keep the group if it matches
          }

          return null // Exclude groups without matching tags
        })
        .filter(group => group !== null)

      fullListRef.current = filteredList // Update the reference with the filtered version
    }

    // Check if list is populated before further processing
    if (fullListRef.current.length > 0) {
      // Put customers and groups data into a flat list for shift selection handling
      const customerFlatListData = fullListRef.current
        .map(group => {
          // Sort clients
          const sortedClients = [...group.clients].sort((a, b) =>
            a.name.localeCompare(b.name)
          )

          return sortedClients.map(client => ({
            ...client,
            group: group.name,
          }))
        })
        .flat() // Flatten after sorting
      customerFlatListRef.current = customerFlatListData

      const groupFlatListData = fullListRef.current.map(group => ({
        group: group.name,
        clients: group.clients.map(client => client.name),
      }))
      groupFlatListRef.current = groupFlatListData

      let opts = []
      let counter = 1

      // Not in use right now
      //console.log("Data Array", data.array)
      // let datas = fullListRef.current
      // let slicing = datas.map(obj => {
      //   const separatedGroups = separateGroups(obj.clients, 10)
      //   return { ...obj, clients: separatedGroups }
      // })
      // slicing.forEach(group => {

      // Process data to be used in treeselect
      fullListRef.current.forEach(group => {
        let groupValue = new Object()
        groupValue.group = group.name

        let groupObject = new Object()
        groupObject.id = counter++
        groupObject.label = group.name
        groupObject.value = JSON.stringify(groupValue)
        groupObject.tags = group.tags
        groupObject.children = []

        group.clients.sort((a, b) => (a.name > b.name ? 1 : -1))
        group.clients.forEach(client => {
          if (client.subgroup) {
            sg.group = sg.name
            let clientObject = new Object()
            let subgroup = client.subgroup(sg => {
              sg.group = sg.name
              // let clientObject = new Object()
              clientObject.title = sg.name
              clientObject.value = JSON.stringify(sg)
              groupObject.children.push(clientObject)
            })

            groupObject.children.push(subgroup)
          } else {
            client.group = group.name
            let clientObject = new Object()
            clientObject.title = client.name
            clientObject.value = JSON.stringify(client)
            groupObject.children.push(clientObject)
          }
        })
        opts.push(groupObject)
      })

      let values = []
      values = opts.map(v => v.value)
      setAllGroups(values)

      let temp = treeOptions
      temp.onChange = onChange
      let all = [
        {
          id: 0,
          key: "all",
          value: "all",
          label: props.t("All groups"),
          children: opts,
        },
      ]
      temp.treeData = all

      // Empty the selection before populating the tree with new data
      temp.value = undefined
      handleSelectionChange([])
      setSelectAll(false)

      setTreeOptions(temp)

      props.allCustomers(opts) // Used only in reporting with tags
    }
  }, [clientTreeDataRef.current, props.selectedTags])

  // Get dropdown options from server
  useEffect(() => {
    const apiDurationStart = performance.now()
    console.log("API call innitiated")
    setLoading(true)
    getClientList(controller)
      .then(data => {
        if (data.array != null) {
          clientTreeDataRef.current = data.array
        }
        const apiDurationEnd = performance.now()
        const durationInSeconds = (
          (apiDurationEnd - apiDurationStart) /
          1000
        ).toFixed(3)
        console.log(`API Call Duration: ${durationInSeconds} seconds`)
      })
      .catch(e => {
        console.log(e)
      })
      .finally(() => {
        setLoading(false)
      })
    return () => {
      controller.abort()
    }
  }, [])

  // Event listeners to track shift key press
  useEffect(() => {
    const handleKeyDown = e => {
      if (e.key === "Shift") {
        setIsShiftPressed(true)
        isShiftPressedRef.current = true
      }
    }
    const handleKeyUp = e => {
      if (e.key === "Shift") {
        setIsShiftPressed(false)
        isShiftPressedRef.current = false
      }
    }
    window.addEventListener("keydown", handleKeyDown)
    window.addEventListener("keyup", handleKeyUp)
    return () => {
      window.removeEventListener("keydown", handleKeyDown)
      window.removeEventListener("keyup", handleKeyUp)
    }
  }, [])

  // Handle "Show all"
  useEffect(() => {
    if (selectAll) {
      console.log("All groups")
      let selections = []

      if (allGroups != null) {
        allGroups.forEach(selection => {
          try {
            let temp = JSON.parse(selection)
            let newEntry = new Object()
            newEntry.group = temp.group
            newEntry.list = []
            selections.push(newEntry)
          } catch (err) {}
        })
      }
      props.setCustomers(selections)
    }
  }, [selectAll, allGroups, treeOptions])

  // Custom empty/Data not found dropdown
  const customizeRenderEmpty = () => (
    <div className="mt-3" style={{ textAlign: "center" }}>
      {loading ? (
        <p>{props.t("Loading")}</p>
      ) : (
        <p>{props.t("No customers found")}</p>
      )}
    </div>
  )

  // Not in use
  // const separateGroups = (clients, listSize) => {
  //   const separatedGroups = []
  //   if (clients.length <= listSize) {
  //     return clients
  //   }
  //   if (clients.length > listSize) {
  //     for (let i = 0; i < clients.length; i += listSize) {
  //       // const groupName = separatedGroups.length + 1
  //       // const group = clients.slice(i, i + listSize)
  //       const group = {
  //         [props.t("Subgroup") + separatedGroups.length + 1]: clients.slice(
  //           i,
  //           i + listSize
  //         ),
  //       }
  //       separatedGroups.push(group)
  //     }

  //     return separatedGroups
  //   }
  // }

  // Handle selecting from antd tree component
  const onChange = value => {
    let finalSelection

    // Handle selection clearing
    if (!value || value.length === 0) {
      setTreeOptions({
        ...treeOptions,
        value: [],
      })
      handleSelectionChange([])
      setSelectAll(false)
      return
    }

    const selectedValues = value
    const lastSelectedValue = selectedValues[selectedValues.length - 1]

    // Check if all is selected before attempting JSON.parse
    if (lastSelectedValue === "all") {
      handleSelectionChange(["all"])
      setTreeOptions({ ...treeOptions, value: ["all"] })
      return
    }

    let parsedValue
    try {
      parsedValue = JSON.parse(lastSelectedValue)
    } catch (err) {
      console.error("Invalid JSON:", lastSelectedValue, err)
      return // Skip further processing if invalid JSON
    }

    // Handle range selection
    const currentCustomerId = parsedValue.id
    const currentGroupId = parsedValue.group
    if (
      isShiftPressedRef.current &&
      (lastSelectedCustomerRef.current || lastSelectedGroupRef)
    ) {
      // Handle customer range selection
      if (currentCustomerId) {
        let startIndex = customerFlatListRef.current.findIndex(
          c => c.id === lastSelectedCustomerRef.current
        )
        let endIndex = customerFlatListRef.current.findIndex(
          c => c.id === currentCustomerId
        )

        if (startIndex !== -1 && endIndex !== -1) {
          const [from, to] =
            startIndex < endIndex
              ? [startIndex, endIndex]
              : [endIndex, startIndex]
          const customerRange = customerFlatListRef.current.slice(from, to + 1)
          finalSelection = customerRange.map(customer =>
            JSON.stringify(customer)
          )
        }
        // Handle group customerRange
      } else if (currentGroupId) {
        let startIndex = groupFlatListRef.current.findIndex(
          c => c.group === lastSelectedGroupRef.current
        )
        let endIndex = groupFlatListRef.current.findIndex(
          c => c.group === currentGroupId
        )

        if (startIndex !== -1 && endIndex !== -1) {
          const [from, to] =
            startIndex < endIndex
              ? [startIndex, endIndex]
              : [endIndex, startIndex]
          const groupRange = groupFlatListRef.current.slice(from, to + 1)
          finalSelection = groupRange.map(item =>
            JSON.stringify({ group: item.group })
          )
        }
      }
    } else {
      // Handle individual selection
      finalSelection = value
    }
    // Handle final selection
    setTreeOptions({ ...treeOptions, value: finalSelection })
    handleSelectionChange(finalSelection)

    // Update the last selected values
    lastSelectedCustomerRef.current = currentCustomerId
    lastSelectedGroupRef.current = currentGroupId
  }

  // Handle selections and update parent component
  const handleSelectionChange = value => {
    // Handle empty selections
    if (!value || value.length === 0) {
      props.setCustomers([])
      return
    }

    let selections = []

    // Handle select all
    if (value.includes("all")) {
      setSelectAll(true)
    } else {
      setSelectAll(false)
      // Process each selected value individually
      value.forEach(selection => {
        try {
          let temp = JSON.parse(selection)
          if (temp.id != null) {
            let matchFound = false
            // Check if the group already exists in the selections array
            selections.forEach(entry => {
              if (temp.group === entry.group) {
                entry.list.push(temp.id)
                matchFound = true
              }
            })
            // If the group was not found, create a new entry for the group
            if (!matchFound) {
              let newEntry = { group: temp.group, list: [temp.id] }
              selections.push(newEntry)
            }
          } else {
            // Handle only group if no ID was found
            let newEntry = { group: temp.group, list: [] }
            selections.push(newEntry)
          }
        } catch (err) {
          console.error("Invalid JSON during selection:", selection, err)
        }
      })
    }
    // Push selections to parent
    props.setCustomers(selections)
  }

  return (
    <ConfigProvider renderEmpty={customize && customizeRenderEmpty}>
      <TreeSelect
        popupClassName="custom-scrollbar"
        loading={loading}
        {...treeOptions}
      ></TreeSelect>
    </ConfigProvider>
  )
}
SelectDropdownTree.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
  selection: PropTypes.string,
  onChange: PropTypes.func,
  setCustomers: PropTypes.any,
  setDisabled: PropTypes.any,
  placeholder: PropTypes.any,
  disabled: PropTypes.any,
  allGroups: PropTypes.any,
  allCustomers: PropTypes.any,
  options: PropTypes.any,
  tags: PropTypes.any,
  selectedTags: PropTypes.any,
}

export default withTranslation()(SelectDropdownTree)
