/***********************
 * CALLS TABLE
 * (TABLE + IMPORT PDF/XLSX)
 ************************/

import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Card,
  CardBody,
  Tooltip,
} from "reactstrap"
import PropTypes from "prop-types"

// Datatable related plugins
import { Table as AntdTable } from "antd"

//Excel export
import XLSX from "xlsx"

//Autotable PDF
import jsPDF from "jspdf"
import "jspdf-autotable"

//Translation
import { withTranslation } from "react-i18next"
import {
  defaultPageSizeOptions,
  showToast,
  stringSorter,
  numberSorter,
} from "utils/general"

const TableGroupLicenses = props => {
  //Excel and pdf name
  const [title] = useState(props.t("Group call licenses"))
  //Table data state
  const [series, setSeries] = useState([])
  const [seriesTotal, setSeriesTotal] = useState([])
  //Dropdown button state
  const [menu, setMenu] = useState(false)
  // Tooltip state for download button
  const [downloadstatstableTT, setDownloadstatstableTT] = useState(false)

  //Setting data state
  useEffect(() => {
    if (props.data !== undefined) {
      if (props.data?.entries?.length > 0) {
        setSeries(props.data.entries)

        // Set series that includes total entry for exports
        let withTotal = [...props.data.entries]
        let temp = {
          group: props.t("All"),
          hosts: props.data.hosts,
          guests: props.data.guests,
          hostAccounts: props.data.hostAccounts,
          guestClients: props.data.guestClients,
        }
        withTotal.unshift(temp)
        setSeriesTotal(withTotal)
      } else {
        setSeries([])
      }
    }
  }, [props.data])

  // Table structure
  const columns = [
    {
      dataIndex: "group",
      title: props.t("Group"),
      sorter: (a, b) => {
        return stringSorter(a, b, "group")
      },
      width: "140px",
      ellipsis: true,
    },
    {
      dataIndex: "hosts",
      title: props.t("Group hosts"),
      sorter: (a, b, sortOrder) => {
        return numberSorter(a["hosts"], b["hosts"], sortOrder)
      },
      width: "140px",
      ellipsis: true,
    },
    {
      dataIndex: "guests",
      title: props.t("Group guests"),
      sorter: (a, b, sortOrder) => {
        return numberSorter(a["guests"], b["guests"], sortOrder)
      },
      width: "140px",
      ellipsis: true,
    },
  ]

  // Export PDF table
  const exportPDF = () => {
    let message = props.t("PDF file exported succesfully!")
    const unit = "pt"
    const size = "A4" // Use A1, A2, A3 or A4
    const orientation = "landscape" // portrait or landscape
    const theme = "grid" //striped,  plain

    const marginLeft = 40
    const doc = new jsPDF(orientation, unit, size, theme)

    doc.setFontSize(14)

    const data = seriesTotal.map(elt => [
      elt.group,
      elt.hosts,
      elt.hostAccounts.join(", "),
      elt.guests,
      elt.guestClients.join(", "),
    ])

    let content = {
      startY: 50,
      head: [
        [
          props.t("Group"),
          props.t("Group hosts"),
          props.t("Group hosts"),
          props.t("Group guests"),
          props.t("Group guests"),
        ],
      ],
      body: data,
    }

    doc.text(title, marginLeft, 40)
    doc.autoTable(content)
    doc.save(title + ".pdf")

    //Show toast
    showToast(message, "success", 5000, "toast-bottom-left")
  }

  // Creating Excel workbook+worksheet+download
  const downloadExcel = () => {
    let message = props.t("Excel file exported succesfully!")
    const wb = XLSX.utils.book_new()

    const Heading = [
      [
        props.t("Group"),
        props.t("Group hosts"),
        props.t("Group hosts"),
        props.t("Group guests"),
        props.t("Group guests"),
      ],
    ]
    const data = seriesTotal.map(elt => [
      elt.group,
      elt.hosts,
      elt.hostAccounts.join(", "),
      elt.guests,
      elt.guestClients.join(", "),
    ])

    // creating sheet and adding data from 2nd row of column A.
    // Leaving first row to add Heading
    const ws = XLSX.utils.json_to_sheet(data, {
      origin: "A2",
      skipHeader: true,
    })
    XLSX.utils.sheet_add_aoa(ws, Heading, { origin: "A1" })
    XLSX.utils.book_append_sheet(wb, ws, title)
    XLSX.write(wb, { bookType: "xlsx", type: "buffer" })
    XLSX.writeFile(wb, title + " (" + new Date() + ")" + ".xlsx")

    //Show toast
    showToast(message, "success", 5000, "toast-bottom-left")
  }

  return (
    <React.Fragment>
      <div>
        <Card className="mt-2">
          <CardBody>
            <Row>
              <Col className="col-12">
                <Col lg="12">
                  <Row className="mb-1">
                    <Col xl="4" xs="6">
                      <div className="mt-2">
                        <p>
                          {series.length} {props.t("found results")}
                        </p>
                      </div>
                    </Col>
                    <Col xl="8" xs="6">
                      <span className="float-end mt-1">
                        <Tooltip
                          placement="bottom"
                          isOpen={downloadstatstableTT}
                          target="downloadstatstable"
                          toggle={() => {
                            setDownloadstatstableTT(!downloadstatstableTT)
                          }}
                        >
                          {props.t("Download statistics")}
                        </Tooltip>
                        <Dropdown
                          id="printButtonServicesStats"
                          isOpen={menu}
                          toggle={() => setMenu(!menu)}
                          className="download-btn text-center"
                          type="button"
                        >
                          <DropdownToggle
                            id="downloadstatstable"
                            className="text-muted"
                            tag="i"
                          >
                            <i className="mdi mdi-download" />
                          </DropdownToggle>
                          <DropdownMenu>
                            <DropdownItem onClick={() => downloadExcel()}>
                              {props.t("Download .xlsx")}
                            </DropdownItem>
                            <DropdownItem onClick={() => exportPDF()}>
                              {props.t("Download .pdf")}
                            </DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                      </span>
                    </Col>
                  </Row>
                </Col>
                <div className="extra-padding">
                  <AntdTable
                    // className="with-extra-row"
                    bordered={false}
                    size="middle"
                    // sticky
                    rowKey={"group"}
                    columns={columns}
                    dataSource={series}
                    pagination={{
                      showSizeChanger: true,
                      defaultPageSize: 50,
                      pageSizeOptions: defaultPageSizeOptions,
                    }}
                    // tableLayout="auto"
                    scroll={{
                      x: "max-content",
                      y: "70vh",
                    }}
                  />
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  )
}

TableGroupLicenses.propTypes = {
  t: PropTypes.any,
  data: PropTypes.any,
}

export default withTranslation()(TableGroupLicenses)
